import * as React from "react";
import url from "url";
import authModuleCss from "./auth.module.css";
import Typography from "../Typography";

interface Props {}

class SocialAuth extends React.Component<Props> {
  public render() {
    const API_BASE_URL = process.env.GATSBY_API_BASE_URL;
    const googleLink = url.resolve(API_BASE_URL, "auth/google");
    const vkLink = url.resolve(API_BASE_URL, "auth/vkontakte");
    const fbLink = url.resolve(API_BASE_URL, "auth/facebook");

    return (
      <div className={authModuleCss.socialAuthContainer}>
        <Typography type="boldText" black>
          Войти через:
        </Typography>
        <div className={authModuleCss.socialButtons}>
          <a href={vkLink}>
            <img
              src="https://img.icons8.com/color/48/000000/vk-circled.png"
              alt="vkontakte"
            />
          </a>
          <a href={fbLink}>
            <img
              src="https://img.icons8.com/color/48/000000/facebook-new.png"
              alt="facebook"
            />
          </a>
          <a href={googleLink}>
            <img
              src="https://img.icons8.com/color/48/000000/google-logo.png"
              alt="google"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default SocialAuth;
